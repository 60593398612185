







































// Components
import { Component, Vue } from 'vue-property-decorator';
import PieChart from '@/components/charts/pieChart';
import { getComponent } from '@/utils/helpers';

// GraphQL
import dashboardAppsyncClient from '@/lib/appsync/dashboard';
import getDashboardData from '@/graphql/getDashboardData.graphql';

// Interfaces
import AutomationTestChartData from '@/interfaces/automationTestChartData'; // eslint-disable-line

// Dates
import { formatDate } from '@/utils/dateHelpers';

import { namespace } from 'vuex-class';
import { addMinutes, startOfDay } from 'date-fns';
import MainTimeOption from '@/interfaces/mainTimeOption'; // eslint-disable-line

const appModule = namespace('appModule');

@Component({
  components: {
    PieChart,
    GeneralLayout: () => getComponent('common/GeneralLayout'),
  },
})
export default class DailySummary extends Vue {
  @appModule.Getter
  public currentTimeOption!: MainTimeOption

  public automationTestsCharts: AutomationTestChartData[] = [];

  public pieChartOptions = {
    responsive: true,
    maintainAspectRatio: false,
    legend: {
      display: false,
    },
  };

  public loading: boolean = false;

  public async fetchData(): Promise<void> {
    this.loading = true;
    /*
      Fetch automation results
     */
    const {
      data:
        {
          get_dashboard_data: {
            results: automationResults,
          },
        },
    } = await dashboardAppsyncClient.query({
      query: getDashboardData,
      variables: {
        input: {
          start_date:
            formatDate(startOfDay(addMinutes(
              new Date(), new Date().getTimezoneOffset() - this.currentTimeOption.value * 1440,
            )), "yyyy-MM-dd'T'HH:mm:ss"),
          end_date: formatDate(startOfDay(addMinutes(new Date(), new Date().getTimezoneOffset())),
            "yyyy-MM-dd'T'HH:mm:ss"),
          env: 'prod',
          record_type: 'automation',
        },
      },
    });
    this.automationTestsCharts = automationResults.map((i) => {
      const parsedItem = JSON.parse(i);

      return {
        time: formatDate(parsedItem.time, 'MMM Do'),
        chartData: {
          labels: ['Passed', 'Failed', 'Skipped'],
          datasets: [
            {
              backgroundColor: [
                '#4B9C1D',
                '#F10227',
                '#F8E700',
              ],
              data: [
                parsedItem.result.filter((r) => r.result === 'PASS').length,
                parsedItem.result.filter((r) => r.result === 'FAIL').length,
                parsedItem.result.filter((r) => r.result === 'SKIPPED').length,
              ],
              borderWidth: 0.5,
              borderAlign: 'inner',
            },
          ],
        },
      };
    });

    this.loading = false;
  }

  async created() {
    await this.fetchData();
    this.$eventBus.$on('timeAppChanged', () => {
      this.fetchData();
    });
  }
}
